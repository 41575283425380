<script>
import { mapActions, mapMutations, mapGetters } from "vuex";

import Footer from "@/layouts/Footer.vue";
import NavBarVotacion from "@/components/NavBarVotacion.vue";
import Modal from "@/components/Modal.vue";
import * as getReports from "../../api/reports/get";

export default {
	props: {
		isTour: {
			required: false,
			type: Boolean,
		},
	},
	components: {
		Footer,
		NavBarVotacion,
		Modal,
	},
	data() {
		return {
			modal: {
				title: null,
				agency: null,
				work_position: null,
				message: null,
				show: false,
			},
			result: null,
		};
	},
	created() {
		this.loadReport();
	},
	computed: {
		...mapGetters({
			isLoading: "loading/isLoading",
			readProfile: "auth/readUser",
			token: "loginStore/readToken",
			readEmitter: "getUsers/readUsers",
		}),
	},
	methods: {
		...mapMutations({
			setLoading: "loading/SET_LOADING",
			//EMITTERS
			getEmitterId: "getUsers/SET_ID",
			setEmitterPagination: "getUsers/SET_PAGINATION",
		}),
		...mapActions({
			getEmitter: "getUsers/getUsers",
		}),
		toggleModal() {
			this.modal.show = !this.modal.show;
		},
		getImage(image) {
			return `${require(`@/assets/images/views${this.$route.path.toUpperCase()}/${image}`)}`;
		},
		loadReport() {
			this.setLoading(true);

			const { territory_id, region_id, agency_id } = this.readProfile;

			let token = this.token;

			let params = {
				page: 1,
				size: 100,
				sort_by: "received_votes",
				sort: "desc",
				territory_id,
				region_id,
				agency_id,
			};

			getReports
				.getVotesByUsers(token, params)
				.then((result) => {
					this.$set(this, "result", result);
					this.loadEmitters(result.items);
				})
				.finally(() => {
					this.listed = true;
					this.setLoading(false);
				});
		},
		loadEmitters(payload) {
			payload.filter((u, key) => {
				this.setEmitterPagination(null);
				this.getEmitterId(u.id);
				this.getEmitter().then((response) => {
					if (response) {
						this.$set(
							this.result.items[key],
							"photo",
							this.readEmitter[0].photo || null
						);
					}
				});
			});
		},
	},
};
</script>
<template>
	<div class="relative h-full flex flex-col justify-center items-center">
		<Modal
			@click.native="toggleModal"
			v-if="modal.show"
			buttonText="Continuar"
		>
			<div class="text-white text-center">
				<span class="b yellow block text-xl">{{ modal.title }}</span>
				<span
					class="block text-xl"
					v-capitalizeWords="modal.work_position"
				></span>
				<span
					class="block text-xl"
					v-capitalizeWords="modal.agency"
				></span>
				<hr class="bg-yellow h-0.5 my-2 w-full block" />
				<span class="block text-lg py-1">{{ modal.message }}</span>
			</div>
		</Modal>
		<div class="my-2 flex flex-col overlay bg-white mx-auto">
			<div
				class="bg-contain bg-top bg-no-repeat mx-5 pt-8 mt-1"
				:style="{
					'background-image': `url(${getImage('usuarios_bg.png')})`,
				}"
			>
				<p class="b bg-overlay text-center text-xl leading-5 mb-3">
					<span class="green block">¡Somos un solo equipo!</span>
				</p>
				<p class="bg-overlay text-lg text-center">
					En
					<span v-capitalizeWords="readProfile.agency.name"></span>
					<span> somos </span>
					<span class="red b">Creadores</span> de
					<span class="blue b">Momentos </span>
					<span class="green b">Felices</span>.
				</p>
			</div>
			<div
				v-if="!isLoading && result.items.length > 0"
				class="messages-list h-4/6 flex-grow overflow-y-scroll pt-3"
			>
				<div
					v-for="(u, key) in result.items"
					:id="`user_${key}`"
					:key="u.id"
					class="message item item--unread gradient-green mb-3 flex justify-between items-center p-2 rounded-xl mx-5 overflow-visible cursor-pointer"
				>
					<span
						class="flex flex-none justify-center items-center icon_user rounded-full overflow-hidden"
					>
						<font-awesome-icon
							v-if="!u.photo"
							icon="fa-solid fa-user"
							class="text-lg"
						/>
						<img
							v-else
							class="block w-full h-full object-cover object-center flex-none transform rotate-90"
							:src="u.photo"
							alt="Trofeo"
						/>
					</span>
					<div class="label w-3/6 pl-2 flex-grow">
						<span
							class="block b text-md truncate"
							v-capitalizeWords="u.name"
						></span>
						<span
							class="block text-md truncate"
							v-capitalizeWords="u.work_position"
						></span>
					</div>
					<div
						class="icon_votes overflow-visible bg-white relative flex-none flex items-center justify-center rounded-full"
					>
						<img
							:src="getImage('voto_h.png')"
							class="block transform scale-125 -ml-2 absolute yellow opacity-90"
						/>
						<span
							class="black b w-9 h-9 relative bg-yellow rounded-full flex-none flex justify-center items-center"
							>{{ u.received_votes }}</span
						>
					</div>
				</div>
			</div>
			<div v-else class="text-md m-auto font-md b blue px-5 text-center">
				Aún no se registran
				<span class="block">reconocimientos para tu equipo</span>
			</div>

			<Nav-bar-votacion :active="this.$route.name"></Nav-bar-votacion>
			<Footer class="static"></Footer>
		</div>
	</div>
</template>
<style lang="scss" scoped>
.overlay {
	height: calc(100% - 1rem);
}
.image {
	height: get-vh(860px);
	margin-bottom: -2rem;
}

.message {
	border: get-vh(8px) solid #d6e2f0;
}

.item {
	box-shadow: 0px 8px 6px rgba($color: #224c57, $alpha: 0.25);

	&--unread {
		box-shadow: none;
		color: #fff;
		border: none;

		.icon_user {
			color: $red;
			background: $gray-light;
		}
	}
}

.icon_user,
.icon_votes {
	box-sizing: content-box;
	border: get-vh(8px) solid #d6e2f0;
	width: get-vh(140px);
	height: get-vh(140px);
}
</style>
