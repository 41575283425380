<script>
export default {
	name: 'Footer',
	data() {
		return {};
	},
	methods: {
		getImage: image => {
			return `${require(`@/assets/images/layouts/FOOTER/${image}`)}`;
		}
	}
};
</script>

<template>
	<div class="footer bottom-0 mx-auto w-full">
		<div
			class="container h-12 flex items-center justify-between m-auto px-5 md:px-4"
		>
			<div class="footer__left">
				<span
					class="bg-yellow inline-block  mr-2 rounded-full w-2 h-2"
				></span>
				<span
					class="bg-green inline-block  mr-2 rounded-full w-2 h-2"
				></span>
				<span
					class="bg-red inline-block  mr-2 rounded-xl w-12 h-2"
				></span>
			</div>
			<div class="footer__center">
				<img
					class="inline-block h-6"
					:src="getImage('logo_footer.png')"
					alt="Red Unica Logo"
				/>
			</div>
			<div class="footer__right">
				<span
					class="bg-red inline-block  ml-2 rounded-xl w-12 h-2"
				></span>
				<span
					class="bg-green inline-block  ml-2 rounded-full w-2 h-2"
				></span>
				<span
					class="bg-yellow inline-block  ml-2 rounded-full w-2 h-2"
				></span>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
</style>
